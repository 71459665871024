import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Image, { ImagePropTypes } from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import Button from "./core-button"
const StyledProfileImageWrapper = styled(ImageWrapper)``
const StyledProfileTile = styled.div`
  display: flex;
  flex-direction: column;
  & > ${StyledProfileImageWrapper} {
    order: -1;
  }
  & > h3 {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    & > a {
      border-radius: 4px;
      margin-left: 8px;
      padding: 2px;
      color: white;
      background: black;
      :hover {
        color: ${props => props.theme.primary};
      }
      & > i.icon {
        font-size: 20px;
      }
    }
  }
  & > h4 {
    font-size: 1rem;
  }
`
const ProfileTile = ({ profile, subtitleField }) => {
  const { name, images, links } = profile
  const hasLinkedIn = links.find(link => link.icon === "linkedin")
  return (
    <StyledProfileTile>
      <h3>
        {name}
        {hasLinkedIn && (
          <Button
            icon="linkedin"
            to={links.find(link => link.icon === "linkedin").url}
            external
            variant="icon"
          />
        )}
      </h3>
      {subtitleField && profile[subtitleField] ? (
        <h4>{profile[subtitleField]}</h4>
      ) : null}
      <Image wrapper={StyledProfileImageWrapper} {...images.profileCropped} />
    </StyledProfileTile>
  )
}
export const ProfileTilePropTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  location: PropTypes.string,
  bio: PropTypes.string,
  images: PropTypes.shape({
    profile: PropTypes.shape({ ...ImagePropTypes }),
    profileCropped: PropTypes.shape({ ...ImagePropTypes }),
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
}
ProfileTile.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    location: PropTypes.string,
    bio: PropTypes.string,
    images: PropTypes.shape({
      profile: PropTypes.shape({ ...ImagePropTypes }),
      profileCropped: PropTypes.shape({ ...ImagePropTypes }),
    }),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        icon: PropTypes.string,
      })
    ),
  }),
  subtitleField: PropTypes.oneOf(["location", "role"]),
}
ProfileTile.defaultProps = {
  subtitleField: "location",
}
export default ProfileTile
