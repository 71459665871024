import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from "@src/components/core-button"
import ProfileTile, {
  ProfileTilePropTypes,
} from "@src/components/content-profile-tile"
import ProfileModal from "@src/components/content-profile-modal"
const StyledTileButton = styled(Button)`
  text-decoration: none;
  white-space: normal;
`
const StyledProfileTiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > *:not(.profile-modal) {
    flex: 0 0 auto;
    margin-bottom: 30px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    & > *:not(.profile-modal) {
      margin: 15px 30px;
      width: 220px;
    }
  }
`
const getProfileTitle = (profile, subtitleField) => {
  const titleParts = []
  if (profile.name) {
    titleParts.push(profile.name)
  }
  if (subtitleField && profile[subtitleField]) {
    titleParts.push(profile[subtitleField])
  }
  return titleParts.join(", ")
}
const ProfileTiles = ({ profiles, subtitleField }) => {
  const [activeProfile, setActiveProfile] = useState(null)
  const handleProfileClick = profile => {
    setActiveProfile(profile)
  }
  const handleProfileClose = () => {
    setActiveProfile(null)
  }
  return (
    <StyledProfileTiles>
      {activeProfile ? (
        <ProfileModal
          className="profile-modal"
          variant="full-height"
          subtitleField={subtitleField}
          profile={activeProfile}
          onClose={() => handleProfileClose()}
          isOpen
        />
      ) : null}
      {profiles.map((profile, index) => (
        <StyledTileButton
          key={`profile-${index}`}
          variant="plain"
          title={getProfileTitle(profile)}
          onClick={() => {
            handleProfileClick(profile)
          }}
        >
          <ProfileTile profile={profile} subtitleField={subtitleField} />
        </StyledTileButton>
      ))}
    </StyledProfileTiles>
  )
}
ProfileTiles.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.shape({ ...ProfileTilePropTypes })),
  subtitleField: PropTypes.oneOf(["location", "role"]),
}
ProfileTiles.defaultProps = {
  subtitleField: "location",
}
export default ProfileTiles
