import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import HeaderSimple from "@src/components/page-header-simple"
import ResponsiveSection from "@src/components/container-responsive-section"
import { ImagePropTypes } from "@src/components/core-image"
import { SITE_DOMAIN } from "@src/utils/constants"
import HtmlContent from "@src/components/core-value-html"
import generateOrganization from "@src/utils/structured-data/generateOrganization"
import ProfileTiles from "@src/components/content-profile-tiles"
import { ProfileTilePropTypes } from "@src/components/content-profile-tile"

const PageTemplate = ({ data }) => {
  const {
    tenAdventures: {
      page: {
        title,
        uri: pageUri,
        images: { header: headerImage },
        seo: pageSeo,
      },
      teamPage: { content },
      teamMembers: { nodes: teamMembers },
    },
  } = data
  GTM.dataLayerPushPageInfo({
    template: "static-about-us",
  })
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={{
          canonical: `${SITE_DOMAIN}${pageUri}`,
          description: pageSeo.metaDesc,
          robots: [pageSeo.metaRobotsNofollow, "index"],
          image: pageSeo.seoImage,
          openGraph: {
            type: pageSeo.opengraphType,
            modifiedTime: pageSeo.opengraphModifiedTime,
          },
          twitter: {
            data: [],
          },
        }}
        structuredData={generateOrganization({
          image: headerImage.src,
        })}
      />
      <HeaderSimple spacer="margin" image={headerImage}>
        <h1>{title}</h1>
      </HeaderSimple>
      <ResponsiveSection>
        <HtmlContent html={content} tableStyle />
      </ResponsiveSection>
      <ResponsiveSection>
        <ProfileTiles profiles={teamMembers} subtitleField="role" />
      </ResponsiveSection>
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,

        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
      teamPage: PropTypes.shape({
        content: PropTypes.string,
      }),
      teamMembers: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            sort: PropTypes.number,
            ...ProfileTilePropTypes,
          })
        ),
      }),
    }),
  }),
  pageContext: PropTypes.shape({
    teamPageId: PropTypes.number,
  }),
}
export default PageTemplate
export const query = graphql`
  query ($id: ID!, $teamPageId: ID!) {
    tenAdventures {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        images {
          header {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        seo {
          ...TenAdventuresGQL_PostSEO
        }
      }
      teamPage: page(id: $teamPageId, idType: DATABASE_ID) {
        content
      }
      teamMembers(first: 100) {
        nodes {
          sort: teamSort
          name: title
          role: teamRole
          location: profileLocation
          bio: content
          images: profileImages {
            profile {
              ...TenAdventuresGQL_ImageSizesQuery
            }
            profileCropped {
              ...TenAdventuresGQL_ImageSizesQuery
            }
          }
          links: profileLinks {
            url
            icon
          }
        }
      }
    }
  }
`
