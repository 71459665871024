import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ModalContainer from "@src/components/container-modal"
import Image, { ImagePropTypes } from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import HtmlContent from "@src/components/core-value-html"
const StyledProfileImageWrapper = styled(ImageWrapper)``
const ProfileBio = styled(HtmlContent)``
const StyledProfileModal = styled(ModalContainer)`
  width: 578px;
  & > div {
    display: flex;
    flex-direction: column;
    & > * {
      flex: 0 1 auto;
    }
    & > h3 {
      margin-bottom: 0;
    }
    & > h4 {
      margin-bottom: 0.5em;
      font-size: 1rem;
      color: ${props => props.theme.darkerGray};
    }
    & > ${StyledProfileImageWrapper} {
      order: -1;
      flex: 1 0.1 220px;
      max-height: 360px;
      margin: -10px -10px 0;
    }
    & > ${ProfileBio} {
      flex: 1 1 auto;
      overflow-y: auto;
      min-height: 6em;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > div {
      & > ${StyledProfileImageWrapper} {
        flex: 0 0.1 360px;
        min-height: 220px;
      }
    }
  }
`
const ProfileModal = ({ profile, subtitleField, ...props }) => {
  const { name, bio, images } = profile
  return (
    <StyledProfileModal {...props}>
      <h3>{name}</h3>
      {subtitleField && profile[subtitleField] ? (
        <h4>{profile[subtitleField]}</h4>
      ) : null}
      <Image wrapper={StyledProfileImageWrapper} {...images.profile} />
      <ProfileBio html={bio} />
    </StyledProfileModal>
  )
}
ProfileModal.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    location: PropTypes.string,
    bio: PropTypes.string,
    images: PropTypes.shape({
      profile: PropTypes.shape({ ...ImagePropTypes }),
      profileCropped: PropTypes.shape({ ...ImagePropTypes }),
    }),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        icon: PropTypes.string,
      })
    ),
  }),
  subtitleField: PropTypes.oneOf(["location", "role"]),
}
export default ProfileModal
